<template>
  <div class="flex-grow container mx-auto pt-8 pb-16 px-4">
    <div class="max-w-[60rem] mx-auto">
      <h1 class="text-xl md:text-2xl font-semibold mb-6">Cookie policy</h1>
      <p class="mb-4" :class="$style.cookiesText">
        We use cookies as necessary to operate this site and to maintain the
        security of your data. We do not use cookies to track you across the
        internet (or to help any third parties to track you). We may in the
        future use cookies and other technology to monitor site usage, but only
        in an anonymous fashion and not in a way which would allow your usage of
        this website to be linked to you personally.
      </p>
      <p :class="$style.cookiesText">
        We do however use the Google reCAPTCHA service to protect our business
        from spam. You can view the relevant Google privacy statement and terms
        at
        <a
          class="text-indigo-700 hover:underline"
          href="https://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          rel="noreferrer noopener"
          >https://www.google.com/intl/en/policies/privacy/</a
        >
        and
        <a
          class="text-indigo-700 hover:underline"
          href="https://www.google.com/intl/en/policies/terms/"
          target="_blank"
          rel="noreferrer noopener"
          >https://www.google.com/intl/en/policies/terms/</a
        >
        respectively.
      </p>

      <div class="mt-6" :class="$style.cookiesTableContainer">
        <table :class="$style.cookiesTable">
          <thead>
            <tr>
              <th class="p-2 w-8 text-left">#</th>
              <th class="p-2 w-[9rem] text-left">Name of cookies</th>
              <th class="p-2 text-left">What it does</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="align-top p-2 w-8 text-left">1</td>
              <td class="align-top p-2 w-[9rem] text-left">_GRECAPTCHA</td>
              <td class="align-top p-2 text-left">
                This cookie is set by Google reCAPTCHA, which protects our site
                against spam enquiries on contact forms. This cookie is set to
                expire after six months. If you would like to delete it sooner
                you can do so via your browser settings once you have left this
                site. Please note that if it happens to reappear after deletion
                without you visiting this website again it is likely to have
                been set by another website using Google reCAPTCHA.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style module>
.cookiesText {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.cookiesTableContainer {
  overflow-x: auto;
}

.cookiesTable {
  min-width: 40rem;
}
</style>
